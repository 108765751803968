.login-default-input {
  --background: var(--input-background);
  --color: var(--input-text-color);
  --padding-top: var(--input-padding-top);
  --padding-bottom: var(--input-padding-bottom);
  --padding-start: var(--input-padding-start);
  --padding-end: var(--input-padding-end);
  font-weight: bold;
  margin: 10px 0;
  border-radius: 10px;
}

input[data-com-onepassword-filled="light"],
select[data-com-onepassword-filled="light"],
textarea[data-com-onepassword-filled="light"] {
  background-color: var(--input-background) !important;
}

.alert-cancel-button {
  color: #c4c4c4 !important;
}

.app-select-modal .alert-wrapper, .campaign-set-select-modal .alert-wrapper {
  --min-width: 600px;
  --min-height: 70vh;

  @media (max-width: 700px) {
    --min-width: 90%;
  }
}

.app-select-modal .alert-radio-group, .campaign-set-select-modal .alert-radio-group {
  max-height: none;
}


.ReactCollapse--collapse {
  transition: height 500ms;
}

.searchWrapper  {
  background: var(--ion-color-light-tint);
  border: 3px solid var(--ion-color-light-tint);
  border-radius: 10px;

  font-weight: 700;
  padding: 5px 10px;
  transition: all .2s ease-in-out;

  &:hover, &:active {
    color: var(--input-text-color);
    background: var(--input-background);
    border: 3px solid var(--plink-blue);
  }

  #search_input {
    margin-left: 16px;
    padding: 5px 0 6px 0;
  }
}

.optionListContainer {
  color: var(--input-background);
  background: var(--ion-color-light-tint);
  border-radius: 10px;
}

.multiSelectContainer ul {
  border-radius: 10px;
  background: var(--ion-color-light-tint);
  border: 3px solid var(--plink-blue);
}

.multiSelectContainer li {
  &:hover {
    background: var(--plink-blue);
  }
}

.highlightOption {
  background: var(--plink-blue);
  color: #fff;
}
